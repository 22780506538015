// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import {translate} from "@/utils";

export const useDocumentsStore = defineStore("documents", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 2,
        current_page: 1,
        filters: [],
        currentItem: {},
        CurrentItemModal: false,
        UpdateItem: false,

    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.header;
            return token
        },
    },
    actions: {
        CreateNewItem(carId) {
          /*  const FormData = require('form-data');
            const fs = require('fs');
            let data = new FormData();
            data.append('car_id', carId);
            data.append('file', fs.createReadStream('/C:/Users/HP/Pictures/dummy.pdf'));
            this.UpdateItem = true
            axios.post(process.env.VUE_APP_API_URL + '/cors/cars/documents', data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                this.ItemsList.push(res.data.result.item)
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })*/
        },
        async fetchList(e) {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/cors/cars/documents/get-by-car/'+e, {
                headers: this.token,
            }).then(res => {
                this.ItemsList = res.data.result.data
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire(translate("error"), error.response.data.message, "error");

            })
        },
    },

});
