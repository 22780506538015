// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import {user_can, translate} from "@/utils";
import CryptoJS from 'crypto-js';
import moment from "moment";

export const useUserDashboard = defineStore("userDashboard", {
    state: () => ({
        loading: false,
        items: {},
        dashboard: {},
        sellersDashboard:{},
        filters :{
            sales_invoices:{
                start_date: moment().startOf('month').format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD')
            }
        }
    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.header;
            return token
        },
    },
    actions: {
        async fetchList() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/cors/reports/my-dashboard', {
                headers: this.token,
            }).then(res => {
                this.loading = false
                this.items = res.data.result
            }).catch(error => {
                this.loading = false
                Swal.fire(translate("error"), error.response.data.message, "error");

            })
        },

        async fetchRapport() {
            this.loading = true;
            let filters = this.filters;

            try {
                const res = await axios.get(`${process.env.VUE_APP_API_URL}/cors/reports/dashboard`, {
                    headers: this.token,
                    params: {
                         filters: filters
                    }
                });
                this.dashboard = res.data.result.categories_data;
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
            } finally {
                this.loading = false;
            }
        },

        async fetchUsersRapport() {
            this.loading = true;
            let filters = this.filters;

            try {
                const res = await axios.get(`${process.env.VUE_APP_API_URL}/cors/reports/seller-report`, {
                    headers: this.token,
                    params: {
                         filters: filters
                    }
                });
                this.sellersDashboard = res.data.result;
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
            } finally {
                this.loading = false;
            }
        }
    },
});
