// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import {useGlobalStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import moment from "moment";
import {user_can,translate} from "@/utils";

export const useSalesInvoicesStore = defineStore("salesInvoices", {
    state: () => ({
        ConvertedInvoiceModal: false,
        NewInvoiceModal: false,
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 200,
        current_page: 1,
        filters: {is_offer:0},
        currentItem: {},
        newItem: {},
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar: 'invoicing/sales_invoices',
        apiSingle: 'item',
        searchedCars: [],
        searchedCustomer: {},
        searchedBranch: null,
        searchedCar: {},
        FetchedPayment: {},
        expired_at: moment().add(7, 'days').format('YYYY-MM-DD'),
        sell_date: moment().add(7, 'days').format('YYYY-MM-DD'),
        vat_method_id:null,
        Carfilters: {
            registration_number: null
        },
        paymentsItems: [],
        is_offer:0
    }),
    getters: {
        vmb: () => {
            const globalStore = useGlobalStore()
            const token = globalStore.allLists['my-vat-methods'].find(x=>x.name === 'VMB');
            return token
        },
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.header;
            return token
        },
        seller: () => {
            const authStore = useAuthStore()
            const id = authStore.currentLoggedUser.user.id;
            return id
        },
        costEx: (state) => {
            if (Object.keys(state.currentItem).length > 0) {
                let cost_articles = state.currentItem.sales_invoice_articles
                let total = []
                if (cost_articles.length > 0) {
                    Array.from(cost_articles).forEach(x => {
                        let amount = 0
                        if (x.vmb === '1') {
                            amount = parseFloat(x.amount)
                        } else {
                            amount = x.amount - x.vat
                        }
                        total.push(amount)
                    })

                    let theTotal = total.reduce((a, b) => {
                        return a + parseFloat(b)
                    }, 0)
                    if (!isNaN(theTotal)) {
                        return theTotal
                    } else {
                        return 0
                    }
                } else {
                    return 0
                }
            }
            return 0

        },
        costInc: (state) => {
            if (Object.keys(state.currentItem).length > 0) {
                let cost_articles = state.currentItem.sales_invoice_articles
                if (cost_articles.length > 0) {
                    let Total = cost_articles.reduce((accumulator, current) => {
                        return accumulator + parseFloat(current.amount)
                    }, 0)

                    if (!isNaN(Total)) {
                        return Total
                    } else {
                        return 0
                    }
                } else {
                    return 0
                }

            } else {
                return 0
            }


        },
        invoiceTotalEx: (state) => {
            if (Object.keys(state.currentItem).length > 0  && state.currentItem.is_offer === 0) {
                let regester_fee = state.currentItem.registration_fees !== null ? parseFloat(state.currentItem.registration_fees) : 0
                let carPrice = parseFloat(state.currentItem.car.out_price)
                let RegesterationFee = parseFloat(regester_fee)
                let discount = state.currentItem.discount_value !== null ? parseFloat(state.currentItem.discount_value) : 0
                let total = (carPrice + RegesterationFee + state.costEx) - discount
                return total
            }else if(Object.keys(state.currentItem).length > 0 && state.currentItem.is_offer === 1){
                let regester_fee = state.currentItem.registration_fees !== null ? parseFloat(state.currentItem.registration_fees) : 0
                let carPrice = parseFloat(state.currentItem.total_offer_ex)
                let RegesterationFee = parseFloat(regester_fee)
                let discount = state.currentItem.discount_value !== null ? parseFloat(state.currentItem.discount_value) : 0
                let total = (carPrice + RegesterationFee + state.costEx) - discount
                return total
            } else {
                return 0
            }
        },
        invoiceTotalInc: (state) => {
            if (Object.keys(state.currentItem).length > 0 && state.currentItem.is_offer === 0) {
                let regester_fee = state.currentItem.registration_fees !== null ? parseFloat(state.currentItem.registration_fees) : 0
                let carPrice = parseFloat(state.currentItem.car.out_price_with_vat)
                let RegesterationFee = parseFloat(regester_fee)
                let discount = state.currentItem.discount_value !== null ? parseFloat(state.currentItem.discount_value) : 0
                let total = (carPrice + RegesterationFee + state.costInc) - discount
                return total
            }else if(Object.keys(state.currentItem).length > 0 && state.currentItem.is_offer === 1){
                let regester_fee = state.currentItem.registration_fees !== null ? parseFloat(state.currentItem.registration_fees) : 0
                let carPrice = parseFloat(state.currentItem.total_offer)
                let RegesterationFee = parseFloat(regester_fee)
                let discount = state.currentItem.discount_value !== null ? parseFloat(state.currentItem.discount_value) : 0
                let total = (carPrice + RegesterationFee + state.costInc) - discount
                return total
            } else {
                return 0
            }
        },
        totalPayments: (state) => {
            if (Object.keys(state.paymentsItems).length > 0) {
                let total = 0
                state.paymentsItems.forEach(x => {
                    let totalX = x.sales_invoice_payment_details.reduce((a, b) => {
                        return a + parseFloat(b.amount)
                    }, 0)

                    total += totalX
                })
                return total

            } else {
                return 0
            }
        },
    },
    actions: {
        commit_searched_car(payload){
           this.searchedCar = payload
        },
        async CancelPayment(itemId, index, e) {
            let endPoint = '/invoicing/payments/cancel'
            if (!e) {
                endPoint = '/invoicing/payments/credit'
            }
            await axios.put(process.env.VUE_APP_API_URL + endPoint + '/' + itemId, {}, {
                headers: this.token
            }).then(res => {
                this.UpdateItem = false;
                // this.paymentsItems[index].fortnox_sales_invoice_payment_id = res.data.result.fortnox_sales_invoice_payment_id
                Swal.fire(translate("success"), "", "success");
            }).catch(error => {
                this.UpdateItem = false;
                Swal.fire(translate("error"), error.response.data.message || "Failed to send payment to FortNox", "error");
            })
        },
        async SendPaymentToFortNox(itemId, index) {
            try {
                await axios.post(process.env.VUE_APP_API_URL + '/cors/fortnox/create-salesInvoice', {
                    id: itemId
                }, {
                    headers: this.token
                }).then(res => {
                    this.UpdateItem = false;
                    this.paymentsItems[index].fortnox_sales_invoice_payment_id = res.data.result.fortnox_sales_invoice_payment_id
                    Swal.fire(translate("success"), translate("Payment_sent_to_FortNox_successfully"), "success");
                })

            } catch (error) {
                this.UpdateItem = false;
                Swal.fire(translate("error"), error.response.data.message || translate("Failed_to_send_payment_to_FortNox"), "error");
                throw error; // Re-throw the error to be caught by the caller
            }
        },
        DeleteItem(i, itemId) {
            user_can('delete_purchase_invoices').then(hasPermission => {
                if (hasPermission) {
                    return axios.delete(process.env.VUE_APP_API_URL + '/' + this.apiPlurar + '/' + itemId, {
                            headers: this.token
                        }
                    ).then(res => {
                        this.ItemsList.splice(i, 1)
                        Swal.fire(translate("success"), res.data.message, "success");
                        this.fetchList()
                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire(translate("error"), error.response.data.message, "error");
                    })
                }
            })

        },
        searchForCar(e) {
            if (this.Carfilters.registration_number !== null && this.Carfilters.registration_number !== "") {
                this.UpdateItem = true
                axios.get(process.env.VUE_APP_API_URL + '/cors/cars', {
                        params: {
                            page: 1,
                            per_page: 10000,
                            filters: this.Carfilters
                        },
                        headers: this.token
                    }
                ).then(res => {
                    this.UpdateItem = false
                    let carsData = res.data.result.cars.data

                    if (carsData.length > 0) {
                        Array.from(carsData).forEach(x => {
                            this.currentItem.sales_invoice_swaps.unshift({
                                car: x,
                                car_swap_price: x.in_price,
                                car_id: x.id
                            })
                        })
                    }

                }).catch(error => {
                    this.UpdateItem = false
                    console.log(error)
                    Swal.fire(translate("error"), error.response, "error");
                })
            }


        },
        async CreateNewItem(searchedCustomer, total_offer_ex, total_offer, setup_fee, monthly_fee) {
            try {
                // Validate car price before proceeding
                if (this.searchedCar.out_price === null || this.searchedCar.out_price_with_vat === null) {
                    Swal.fire(translate("error"), translate('sell_price_required'), "error");
                    return;
                }

                // Prepare total offer values based on offer condition
                total_offer_ex = this.is_offer !== 1 ? null : total_offer_ex;
                total_offer = this.is_offer !== 1 ? null : total_offer;

                // Build the data object for the new item
                const data = {
                    customer_id: searchedCustomer.id,
                    to_branch_id: this.searchedBranch ? this.searchedBranch.id : null,
                    car_id: this.searchedCar.id,
                    seller_id: this.seller,
                    is_offer: this.is_offer,
                    total_offer_ex: total_offer_ex,
                    total_offer: total_offer,
                    setup_fee: setup_fee,
                    monthly_fee: monthly_fee,
                    expired_at: this.expired_at,
                    vat_method_id: this.vat_method_id,
                    car: { sell_date: this.sell_date }
                };

                // Set loading state to true
                this.UpdateItem = true;

                // Send POST request to create new item
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/${this.apiPlurar}`, data, {
                    headers: this.token
                });

                // On success, update items list and current item, open modal
                this.ItemsList.unshift(response.data.result[this.apiSingle]);
                this.currentItem = response.data.result[this.apiSingle];
                this.CurrentItemModal = true;
            } catch (error) {
                this.UpdateItem = false;
                // Error handling
                Swal.fire(translate("error"), error.response?.data?.message || "An error occurred", "error");
            } finally {
                // Reset loading state
                this.UpdateItem = false;
            }
        },
        async convertOfferToAvtal(){
            this.currentItem.is_offer = 0
           await this.PutItem()

        },
        PutItem() {
            this.UpdateItem = true
            let itemId = this.currentItem.id
            let data = {...this.currentItem}
            data.is_company ? data.is_company = 1 : data.is_company = 0
            data.accepted_condition ? data.accepted_condition = 1 : data.accepted_condition = 0
            let credit = data.sales_invoice_credit
            if(credit !== null) {
                delete credit.loanAmount
                delete credit.intrestCost
                delete credit.totalCarCredit
                delete credit.totalCreditCost
            }


            axios.put(process.env.VUE_APP_API_URL + '/' + this.apiPlurar + '/' + itemId, data, {
                    headers: this.token
                }
            ).then(res => {
                this.UpdateItem = false
                Swal.fire(translate("success"), res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        fetchItem(e) {
            axios.get(process.env.VUE_APP_API_URL + '/' + this.apiPlurar + '/' + e, {
                    headers: this.token
                }
            ).then(res => {
                res.data.result[this.apiSingle].is_company = Boolean(parseInt(res.data.result[this.apiSingle].is_company))
                let item = res.data.result[this.apiSingle];
                this.currentItem = item
                this.fetchPayments(item.id)
            }).catch(error => {
                console.log(error)
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        async fetchList(e) {
            if(e){
                this.filters = {is_offer:1}
            }else{
                this.filters = {is_offer:0}
            }
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/' + this.apiPlurar, {
                params: {
                    page: this.current_page,
                    per_page: this.per_page,
                    filters: this.filters
                },
                headers: this.token,
            }).then(res => {
                this.ItemsList = res.data.result.sales_invoices.data
                this.ItemsInfo = res.data.result.sales_invoices
                this.totalItems = res.data.result.sales_invoices.total
                this.current_page = res.data.result.sales_invoices.current_page
                this.per_page = res.data.result.sales_invoices.per_page
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire(translate("error"), error.response.data.message, "error");

            })
        },
        async generateFinalRegulation($t) {
            let items = []
            let carRegulation = {
                article_number: this.currentItem.car.registration_number,
                name: this.currentItem.car.model_year + ': ' + this.currentItem.car.car_model_id + ' ' + this.currentItem.car.chassis_number,
                quantity: '1',
                vat_method_id: this.currentItem.car.vat_method.id,
                vat_method: this.currentItem.car.vat_method,
                ex_price: this.currentItem.car.out_price,
                amount: (parseFloat(this.currentItem.car.out_price) * parseFloat(this.currentItem.car.vat_method.value)) / 100 + parseFloat(this.currentItem.car.out_price),
                inc_price: (parseFloat(this.currentItem.car.out_price) * parseFloat(this.currentItem.car.vat_method.value)) / 100 + parseFloat(this.currentItem.car.out_price)
            }
            items.push(carRegulation)
            if (this.currentItem.sales_invoice_articles.length > 0) {
                this.currentItem.sales_invoice_articles.forEach((x) => {
                    items.push(
                        {
                            vat_method_id: x.vat_method.id,
                            vat_method: x.vat_method,
                            article_number: x.article_number,
                            name: x.name,
                            quantity: x.quantity,
                            vat_percent: x.vat_percent,
                            ex_price: x.price,
                            amount: x.amount,
                            inc_price: x.amount - x.vat,
                        }
                    )
                })
            }
            if (this.currentItem.sales_invoice_swaps.length > 0) {
                this.currentItem.sales_invoice_swaps.forEach((x) => {
                    items.push(
                        {
                            vat_method_id: x.car.vat_method.id,
                            vat_method: x.car.vat_method,
                            article_number: x.car.registration_number,
                            name: x.car.registration_number + ' - ' + x.car.model_year,
                            quantity: 1,
                            vat_percent: x.car.vat_method.value,
                            ex_price: -Math.abs(x.car.in_price),
                            amount: -Math.abs(((x.car.in_price * parseFloat(x.car.vat_method.value)) / 100) + parseFloat(x.car.in_price)),
                            inc_price: -Math.abs(((x.car.in_price * parseFloat(x.car.vat_method.value)) / 100) + parseFloat(x.car.in_price))
                        }
                    )
                })
            }
            if (parseInt(this.currentItem.discount_value) !== 0) {
                items.push(
                    {
                        article_number: 'DISC',
                        name: $t('invoiceCalculater.discount'),
                        vat_method_id: this.vmb.id,
                        vat_method: this.vmb,
                        quantity: 1,
                        vat_percent: '0',
                        ex_price: -Math.abs(this.currentItem.discount_value),
                        amount: -Math.abs(this.currentItem.discount_value),
                        inc_price: -Math.abs(this.currentItem.discount_value)
                    }
                )
            }
            if (parseInt(this.currentItem.registration_fees) !== 0) {
                items.push(
                    {
                        vat_method_id: this.vmb.id,
                        vat_method: this.vmb,
                        article_number: 'REGF',
                        name: $t('invoiceCalculater.Registration_fee'),
                        quantity: 1,
                        vat_percent: '0',
                        ex_price: this.currentItem.registration_fees,
                        amount: this.currentItem.registration_fees,
                        inc_price: this.currentItem.registration_fees
                    }
                )
            }
            console.log(items)
            this.paymentsItems[0].sales_invoice_payment_details = items
            this.UpdatePaymentMethod(this.paymentsItems[0].id, this.paymentsItems[0])
        },
        async CreateNewPaymentItem(d, a, $t) {
            try {
                this.UpdateItem = true;
                let data = d;
                const response = await axios.post(process.env.VUE_APP_API_URL + '/invoicing/payments', data, {
                    headers: this.token
                })
                this.UpdateItem = false;
                this.paymentsItems.unshift(response.data.result.item);
                if (a) {
                    this.generateFinalRegulation($t)
                } else {
                    Swal.fire(translate("success"), response.data.message, "success");
                }

            } catch (error) {
                this.UpdateItem = false;
                Swal.fire("Error!", error, "error");
            }
        },
        DeletePaymentMethod(index, itemId) {
            axios.delete(process.env.VUE_APP_API_URL + '/invoicing/payments/' + itemId, {
                    headers: this.token
                }
            ).then(res => {
                this.paymentsItems.splice(index, 1)
            }).catch(error => {
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        UpdatePaymentMethod(itemId, data) {

            axios.put(process.env.VUE_APP_API_URL + '/invoicing/payments/' + itemId, data, {
                    headers: this.token
                }
            ).then(res => {

                Swal.fire(translate("success"), res.data.message, "success");
            }).catch(error => {
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        async UpdatePaymentMethod_n(itemId, data) {
            try {
                await axios.put(process.env.VUE_APP_API_URL + '/invoicing/payments/' + itemId, data, {
                    headers: this.token
                });
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message || translate('update_payment_method_failed'), "error");
                throw error; // Re-throw the error to be caught by the caller
            }
        },
        FetchPaymentItem(itemId, data) {
            axios.get(process.env.VUE_APP_API_URL + '/invoicing/payments/' + itemId, {
                    headers: this.token
                }
            ).then(res => {
                this.FetchedPayment = res.data.result.item
            }).catch(error => {
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        async fetchPayments(invoiceId) {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/invoicing/payments/get-by-sales-invoice/' + invoiceId, {
                params: {
                    page: 1,
                    per_page: 100000,
                },
                headers: this.token,
            }).then(res => {
                res.data.result.sales_invoice_payments.length > 0 ?
                    this.paymentsItems = res.data.result.sales_invoice_payments :
                    ''
            }).catch(error => {
                console.log(error)
                Swal.fire(translate("error"), error.response.data.message, "error");

            })
        },

    },

});
/*
async fetchItem(e) {
    try {
        const hasPermission = await user_can('read_cars');
        if (!hasPermission) {
            throw new Error('No permission to read cars.');
        }

        const res = await axios.get(`${process.env.VUE_APP_API_URL}/cors/${this.apiPlurar}/${e}`, {
            headers: this.token
        });

        const item = res.data.result[this.apiSingle];

        // Check if item is null or undefined before updating
        if (item) {
            this.CurrentCar = item;
            this.CurrentCar.meter_setting = this.CurrentCar.meter_setting || { value: null, unit: null };
            this.CurrentCar.car_inspections = this.CurrentCar.car_inspections || { date: null, value: null };
            this.CurrentCar.car_creditor_installments = this.CurrentCar.car_creditor_installments || { creditor: [] };
            this.CurrentCar.car_specifications = this.CurrentCar.car_specifications || {
                month_of_manufacture: null,
                manufacture_year: null,
                co2_emissions: { value: null, unit: 'G/Km' },
                efect: { value: null, unit: 'KW' },
                fuel_consumption_mixed: { value: null, unit: '1/MILE' },
                max_load: { value: null, unit: 'KG' },
                max_trailer_weight: { value: null, unit: 'TONE' },
                total_weight: { value: null, unit: 'TONE' },
            };
            this.CurrentCar.car_guarantees = this.CurrentCar.car_guarantees || {
                last_done: { value: null, unit: 'KM' },
                gauge_setting: { value: null, unit: 'KM' }
            };
        } else {
            throw new Error('Item not found.');
        }
    } catch (error) {
        console.error(error);
        Swal.fire('Error', error.message || 'An error occurred while fetching the item.', 'error');
    }
},*/
