import { defineStore } from "pinia";
import axios from "axios";
import { encrypt, user_can } from "@/utils";
import Swal from "sweetalert2";
import { useAuthStore } from "@/state/pinia/auth";
import { translate } from "@/utils";
import moment from "moment";
import qs from "qs";
export const useTestDriveStore = defineStore("testdrive", {
    state: () => ({
        UpdateItem: false,
        newItem:false,
        searchedCustomer: [],
        searchedCar: {},
        currentItem: {},
        preventSuccessMsg: false,
        CurrentItemModal: false,
        ItemsList: [],
        loading: true,
        per_page: 400,
        current_page: 1,
        filters: {},
        totalItems: 0,
        ItemsInfo: {},
        itemsPerPage: 18,
    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.header;
            return token
        },
    },
    actions: {
        async PutItem() {

            try {
                const hasPermission = await user_can('edit_cars');
                if (hasPermission) {
                    this.UpdateItem = true;
                    const itemId = this.currentItem.id;
                    const data = {...this.currentItem};
                    // Force control_points to be explicitly set
                    if (Array.isArray(this.currentItem.control_points) && this.currentItem.control_points.length === 0) {
                      data.control_points = '';  // Send as empty array if it is an empty array
                    }

                    const response = await axios.put(`${process.env.VUE_APP_API_URL}/invoicing/brokerage_agreements/${itemId}`, data, {
                        headers: this.token,
                        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets', skipNulls: false }),
                    });

                    this.UpdateItem = false;

                    if (!this.preventSuccessMsg) {
                        Swal.fire(translate("success"), response.data.message, "success");
                    }

                    this.preventSuccessMsg = false;
                }
            } catch (error) {
                this.UpdateItem = false;
                const errorMessage = error.response?.data?.message || error.message || "An unexpected error occurred";
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },

        async CreateItem(car) {
            try {
                const hasPermission = await user_can('write_cars');
                if (hasPermission) {
                    this.UpdateItem = true;
                    const data = JSON.parse(JSON.stringify(car));

                    const res = await axios.post(`${process.env.VUE_APP_API_URL}/invoicing/brokerage_agreements`, data, {
                        headers: this.token,
                    });

                    this.CurrentItemModal = false;
                    this.ItemsList.unshift(res.data.result.item);
                    this.UpdateItem = false;
                } else {
                    throw new Error('You do not have permission to create this item.');
                }
            } catch (error) {
                this.UpdateItem = false;
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },

        async fetchList() {
            try {
                const hasPermission = await user_can('read_cars');
                if (hasPermission) {
                    this.loading = true;
                    const res = await axios.get(`${process.env.VUE_APP_API_URL}/cors/test-drives`, {
                        params: {
                            page: this.current_page,
                            per_page: this.per_page,
                            filters: this.filters,
                        },
                        headers: this.token,
                    });

                    this.ItemsList = res.data.result.test_drives.data;
                    this.ItemsInfo = res.data.result.test_drives;
                    this.totalItems = res.data.result.test_drives.total;
                    this.current_page = res.data.result.test_drives.current_page;
                    this.per_page = res.data.result.test_drives.per_page;
                    this.loading = false;
                }
            } catch (error) {
                this.loading = false;
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },

        async fetchItem(id) {
            try {
                const hasPermission = await user_can('read_cars');
                if (hasPermission) {
                    const res = await axios.get(`${process.env.VUE_APP_API_URL}/cors/test-drives/${id}`, {
                        headers: this.token,
                    });
                    this.currentItem = res.data.result.item;
                } else {
                    throw new Error('No permission to read creditors.');
                }
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },

        async CreateNewItem(data) {
            this.UpdateItem = true
            let payload = {...data}

            try {
                    const res = await axios.post(`${process.env.VUE_APP_API_URL}/cors/test-drives`, payload,{
                        headers: this.token,
                    });

                    this.currentItem = res.data.result.item;
                    this.CurrentItemModal = false;
                    this.UpdateItem = false
            } catch (error) {
                this.UpdateItem = false
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },

    },


});
