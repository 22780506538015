// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs";
import {user_can,translate} from "@/utils";

export const useDistirbutorsStore = defineStore("distirbutors", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 400,
        current_page: 1,
        filters: [],
        currentItem: {},
        newItem: {},
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar: 'distributors',
        apiSingle: 'item',

    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.header;
            return token
        },
    },
    actions: {
        CreateNewItem() {
            user_can('write_distributors').then(hasPermission => {
                if (hasPermission) {
                    this.UpdateItem = true
                    let data = this.newItem

                    axios.post(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar, data, {
                            headers: this.token
                        }
                    ).then(res => {
                        this.UpdateItem = false
                        this.ItemsList.unshift(res.data.result[this.apiSingle])
                        Swal.fire(translate("success"), res.data.message, "success");
                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire(translate("error"), error.response.data.message, "error");
                    })
                }
            })

        },
        DeleteItem(i, itemId) {
            user_can('delete_distributors').then(hasPermission => {
                if (hasPermission) {
                    return axios.delete(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar + '/' + itemId, {
                            headers: this.token
                        }
                    ).then(res => {
                        this.ItemsList.splice(i, 1)
                        Swal.fire(translate("success"), res.data.message, "success");
                        this.fetchList()
                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire(translate("error"), error.response.data.message, "error");
                    })
                }
            })

        },
        PutItem() {
            user_can('edit_distributors').then(hasPermission => {
                if (hasPermission) {
                    this.UpdateItem = true
                    let itemId = this.currentItem.id
                    let data = this.currentItem
                    return axios.put(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar + '/' + itemId, data, {
                        headers: this.token
                    }).then(res => {
                        this.UpdateItem = false
                        this.ItemsList.find(x => x.id === itemId).name = this.currentItem.name
                        Swal.fire(translate("success"), res.data.message, "success");
                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire(translate("error"), error.response.data.message, "error");
                    })
                }
            })

        },
        fetchItem(e) {
            user_can('read_distributors').then(hasPermission => {
                if (hasPermission) {
                    return axios.get(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar + '/' + e, {
                        headers: this.token
                    });
                } else {
                    throw new Error('No permission to read creditors.');
                }
            }).then(res => {
                let item = res.data.result[this.apiSingle];
                this.currentItem = item;
            }).catch(error => {
                console.log(error);
                Swal.fire(translate("error"), error.message || error.response.data.message, "error");
            });
        },
         fetchList() {
            user_can('read_distributors').then(hasPermission => {
                if (hasPermission) {
                    this.loading = true
                    return axios.get(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar, {
                        params: {
                            page: this.current_page,
                            per_page: this.per_page,
                            filters: this.filters
                        },
                        headers: this.token,
                    }).then(res => {
                        this.ItemsList = res.data.result[this.apiPlurar].data
                        this.ItemsInfo = res.data.result[this.apiPlurar]

                        this.totalItems = res.data.result[this.apiPlurar].total
                        this.current_page = res.data.result[this.apiPlurar].current_page
                        this.per_page = res.data.result[this.apiPlurar].per_page
                        this.loading = false
                    }).catch(error => {
                        this.loading = false
                        console.log(error)
                        Swal.fire(translate("error"), error.response.data.message, "error");

                    })
                }
            })


        },
    },

});
