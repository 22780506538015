<template>
  <router-view></router-view>
</template>

<script>
import {useAuthStore} from "@/state/pinia/auth";
import {messaging, getToken} from "@/firebase";

export default {
  name: 'App',
  components: {},
  methods: {
    async initAuth() {
      const auth = useAuthStore()
      await auth.getFcm()
      setTimeout(function () {
        auth.getUser()
      }, 200)

    }
  },
  created() {
    this.initAuth()

  },
}
</script>
<style>
.table > :not(caption) > * > * {
  padding-left: 0 !important;
}
</style>

