import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebase = {
    apiKey: "AIzaSyCyxZNr0UVxzHlFgEAjDcEPP6x_S-1a1OA",
    authDomain: "carsystem-11692.firebaseapp.com",
    projectId: "carsystem-11692",
    storageBucket: "carsystem-11692.appspot.com",
    messagingSenderId: "742765759835",
    appId: "1:742765759835:web:d4d57ac26974616cdd1e7e",
    measurementId: "G-BL27PEQZ6T"
};

const firebaseApp = initializeApp(firebase);
const messaging = getMessaging(firebaseApp);

export { messaging, getToken, onMessage };
