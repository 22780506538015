import {useAuthStore} from "@/state/pinia";

import moment from "moment";
import CryptoJS from 'crypto-js'
import i18n from "@/i18n";
export function encrypt(purchaseId, ) {
    const encrypted = CryptoJS.TripleDES.encrypt(purchaseId.toString(), process.env.VUE_APP_DECODE_PASS).toString();
    console.log(encrypted)
    return encrypted;
}
export function decrypt(purchaseId) {
    const decrypted = CryptoJS.TripleDES.decrypt(purchaseId, process.env.VUE_APP_DECODE_PASS).toString(CryptoJS.enc.Utf8);
    return decrypted;
}
export function translate(key, options) {

    if(options !== undefined){
        return i18n.global.t(key, options);
    }

}
export function formatPhoneNumber(phoneNumber, countryCode) {
    // Ensure the country code starts with '+'
    if (!countryCode.startsWith('+')) {
        countryCode = '+' + countryCode;
    }

    // Remove spaces, dashes, and parentheses
    phoneNumber = phoneNumber.replace(/[\s-()]/g, '');

    // Check if the phone number already starts with the country code
    if (phoneNumber.startsWith(countryCode)) {
        return phoneNumber; // Return as is if it already includes the country code
    }

    // Remove leading zeros if the number starts with them
    let formattedNumber = phoneNumber.replace(/^0+/, '');

    // Concatenate the country code with the cleaned number
    return countryCode + formattedNumber;
}
export function formatDate(date) {
    const momentDate = moment(date);
    return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : false;
}

export function formatNum(num) {
    num = parseFloat(num);

    // If it is a float, restrict to 2 decimals
    num = num % 1 !== 0 ? num.toFixed(2) : num.toFixed(0);
    num = num.replace('.', ',');

    return !num ? num : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export async function user_can(e) {
    const auth = useAuthStore();

    // Ensure the user is loaded before proceeding
    if (!auth.currentLoggedUser || auth.currentLoggedUser.permissions === undefined) {
        await auth.getUser();
    }

    // After ensuring the user is loaded, check for permissions
    if (auth.currentLoggedUser && auth.currentLoggedUser.permissions) {
        const permissions = auth.currentLoggedUser.permissions.map(x => x.name);
        return permissions.includes(e);
    }
    // Return false if the user is not loaded or has no permissions
    return false;
}

export  function current_user_can(e) {
    const auth = useAuthStore();


    // After ensuring the user is loaded, check for permissions
    if (auth.currentLoggedUser && auth.currentLoggedUser.permissions) {
        const permissions = auth.currentLoggedUser.permissions.map(x => x.name);
        return permissions.includes(e);
    }
    // Return false if the user is not loaded or has no permissions
    return false;
}
