// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import {user_can,translate} from "@/utils";

export const useUsersStore = defineStore("users", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 400,
        current_page: 1,
        filters: {name: ''},
        currentItem: {},
        newUser: {},
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar: 'users',
        apiSingle: 'item',
        sort_column: 'id',
        sort_direction: 'desc'

    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.header;
            return token
        },
    },
    actions: {
        CreateNewItem() {
            user_can('write_users').then(hasPermission => {
                if (hasPermission) {
                    this.UpdateItem = true
                    let data = {...this.currentItem}
                    /*data.role_id = this.newUser.role.map(x=>{
                        return x.id
                    })*/
                    data.role_id = this.currentItem.roles[0].id
                    data.active = this.currentItem.active ? '1' : '0'
                    data.users_branches = this.currentItem.users_branches.map(x => {
                        return x.id
                    })

                    axios.post(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar, data, {
                            headers: this.token
                        }
                    ).then(res => {
                        this.UpdateItem = false
                        this.ItemsList.push(res.data.result[this.apiSingle])
                        Swal.fire(translate("success"), res.data.message, "success");
                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire(translate("error"), error.response.data.message, "error");
                    })
                } else {
                    Swal.fire(translate("error"), 'You lack the necessary permissions', "error");
                }
            })

        },
        PutItem() {
            user_can('edit_users').then(hasPermission => {
                if (hasPermission) {
                    this.UpdateItem = true
                    let itemId = this.currentItem.id
                    let data = {...this.currentItem}
                    data.active = this.currentItem.active ? '1' : '0'
                    data.role_id = this.currentItem.roles.map(x => {
                        return x.id
                    })
                    data.users_branches = this.currentItem.users_branches.map(x => {
                        return x.id
                    })

                    axios.put(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar + '/' + itemId, data, {
                            headers: this.token
                        }
                    ).then(res => {
                        this.UpdateItem = false
                        Swal.fire(translate("success"), res.data.message, "success");
                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire(translate("error"), error.response.data.message, "error");
                    })
                } else {
                    Swal.fire(translate("error"), 'You lack the necessary permissions', "error");
                }
            })

        },
        ResetUserPass() {
            user_can('edit_users').then(hasPermission => {
                if (hasPermission) {
                    this.UpdateItem = true
                    let itemId = this.currentItem.id

                    axios.get(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar + '/reset-password/' + itemId, {
                            headers: this.token
                        }
                    ).then(res => {
                        this.UpdateItem = false

                        Swal.fire(translate("success"), res.data.result.new_password, "success");
                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire(translate("error"), error.response.data.message, "error");
                    })
                } else {
                    Swal.fire(translate("error"), 'You lack the necessary permissions', "error");
                }
            })

        },
        fetchItem(e) {
            user_can('read_users').then(hasPermission => {
                if (hasPermission) {
                    axios.get(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar + '/' + e, {
                            headers: this.token
                        }
                    ).then(res => {
                        res.data.result[this.apiSingle].active = Boolean(parseInt(res.data.result[this.apiSingle].active))
                        let item = res.data.result[this.apiSingle];
                        this.currentItem = item
                    }).catch(error => {
                        console.log(error)
                        Swal.fire(translate("error"), error.response.data.message, "error");
                    })
                } else {
                    Swal.fire(translate("error"), 'You lack the necessary permissions', "error");
                }
            })

        },
        fetchList() {
            user_can('read_users').then(hasPermission => {
                if (hasPermission) {
                    this.loading = true
                    axios.get(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar, {
                        params: {
                            page: this.current_page,
                            per_page: this.per_page,
                            filters: this.filters
                        },
                        headers: this.token,
                    }).then(res => {
                        this.ItemsList = res.data.result[this.apiPlurar].data
                        this.ItemsInfo = res.data.result[this.apiPlurar]

                        this.totalItems = res.data.result[this.apiPlurar].total
                        this.current_page = res.data.result[this.apiPlurar].current_page
                        this.per_page = res.data.result[this.apiPlurar].per_page
                        this.loading = false
                    }).catch(error => {
                        this.loading = false
                        console.log(error)
                        Swal.fire(translate("error"), error.response.data.message, "error");

                    })
                } else {
                    Swal.fire(translate("error"), 'You lack the necessary permissions', "error");
                }
            })

        },
    },

});
