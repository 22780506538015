// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import {translate} from "@/utils";

export const useSettingsStore = defineStore("settings", {
    state: () => ({
        loading: true,
        ItemsList: [],
        currentItem: {},
        UpdateItem: false,
        oauthUrl:null
    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.header;
            return token
        },
    },
    actions: {
        PutItem() {
            this.UpdateItem = true
            let data = this.ItemsList
            axios.post(process.env.VUE_APP_API_URL + '/cors/setting/update-setting', data, {
                    headers: this.token
                }
            ).then(res => {
                this.UpdateItem = false
                Swal.fire(translate("success"), res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        async fetchList() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/cors/setting/get-setting', {
                headers: this.token,
            }).then(res => {
                this.ItemsList = res.data.result.item
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire(translate("error"), error.response.data.message, "error");

            })
        },
        async retriveFortNoxOauthLink() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/cors/fortnox/url-oauth2', {
                headers: this.token,
            }).then(res => {
                this.oauthUrl = res.data.result['url-oauth2']
                this.loading = false
                window.location = this.oauthUrl
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire(translate("error"), error.response.data.message, "error");

            })
        },

    },

});
