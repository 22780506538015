import {defineStore} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import {formatDate, translate} from "@/utils";
import moment from "moment";

export const useTodosStore = defineStore("todos", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        TodosItemsList: [],
        ItemsInfo: {},
        per_page: 1000,
        current_page: 1,
        filters: [],
        currentItem: {},
        newItem: {},
        CurrentItemModal: false,
        NewItemModal: false,
        UpdateItem: false,
        currentCat:null
    }),
    getters: {
        token() {
            const authToken = useAuthStore();
            return authToken.header;
        },
    },
    actions: {
        async CreateNewItem() {
            this.UpdateItem = true;
            try {
                const {data} = await axios.post(
                    `${process.env.VUE_APP_API_URL}/cors/categories-todos`,
                    this.newItem,
                    {headers: this.token}
                );
                this.ItemsList.unshift(data.result.item);
                Swal.fire(translate("success"), data.message, "success");
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
            } finally {
                this.UpdateItem = false;
            }
        },
        async putTodoOrder(newOrder) {
            this.UpdateItem = true;
            const todoIds = newOrder.map(item => item.id);

            try {
                await axios.post(
                    `${process.env.VUE_APP_API_URL}/cors/todos/reordar`,
                    { todos: todoIds },
                    { headers: this.token }
                );
               // await this.fetchTodosList();
            } catch (error) {
                Swal.fire(translate("error"), error.response?.data?.message || "An error occurred", "error");
            } finally {
                this.UpdateItem = false;
            }
        },

        async createTodo(e) {
            this.UpdateItem = true;
            console.log(e)
            const formData = new FormData();
            const fields = ['end_date', 'is_pinned', 'category_id', 'desc', 'date', 'status'];

// Loop through each field and append to formData if it exists in 'e'
            fields.forEach((field) => {
                if (e[field] !== undefined) {
                    formData.append(field, Array.isArray(e[field]) ? JSON.stringify(e[field]) : e[field]);
                }
            });

            Array.from(e.assigned_to).forEach(x => formData.append('assigned_to[]', x));
            e.image !== undefined && e.image.length > 0 ? Array.from(e.image).forEach(x => formData.append('image[]', x)) : '';

            try {
                const {data} = await axios.post(
                    `${process.env.VUE_APP_API_URL}/cors/todos`,
                    formData,
                    {headers: {...this.token, 'Content-Type': 'multipart/form-data'}}
                );

                // If the API call succeeds, show a success message
                let catIn = this.TodosItemsList.findIndex(x => x.id === e.category_id);
                console.log(data);
                this.TodosItemsList[catIn].todos.unshift(data.result.item);
                Swal.fire(translate("success"), data.message, "success");

                return data;  // Return success data if needed
            } catch (error) {
                // Show error message returned from backend
                Swal.fire(translate("error"), error.response.data.message || 'An error occurred', "error");
                throw error;
            } finally {
                this.UpdateItem = false;
            }
        },
        async putTodo(item) {
            let e = {...item}
            this.UpdateItem = true;
            const itemId = e.id
            delete e.created_at
            delete e.updated_at
            delete e.created_by
            delete e.company_id
            delete e.id
            const formData = new FormData();
         //   let parsedDate = e.date
          /*  let parsedDate = new Date(e.date);
            window.alert(parsedDate)
            if (isNaN(parsedDate.getTime())) {
                // If parsing fails, set e.date to the current date
                window.alert(1)
                parsedDate = moment().format('YYYY-MM-DD');
            }else{
                window.alert(2)
                parsedDate = moment(parsedDate).format('YYYY-MM-DD');
            }*/


            const fields = ['end_date', 'is_pinned', 'category_id', 'desc', 'status','date'];
            formData.append('_method','PUT')
          //  formData.append('date',parsedDate)

// Loop through each field and append to formData if it exists in 'e'
            fields.forEach((field) => {
                if (e[field] !== undefined) {
                    formData.append(field, Array.isArray(e[field]) ? JSON.stringify(e[field]) : e[field]);
                }
            });

            Array.from(e.assigned_users).forEach((x,i)=> formData.append('assigned_to[]', x.id));
            e.image !== undefined && e.image.length > 0 ? Array.from(e.image).forEach(x => formData.append('image[]', x)) : '';

            try {
                const {data} = await axios.post(
                    `${process.env.VUE_APP_API_URL}/cors/todos/${itemId}`,
                    formData,
                    {headers: {...this.token, 'Content-Type': 'multipart/form-data'}}
                );

                // If the API call succeeds, show a success message
                await this.fetchTodoItem(item.id)
              await this.fetchTodosList();
               // Swal.fire(translate("success"), data.message, "success");

                return data;  // Return success data if needed
            } catch (error) {
                // Show error message returned from backend
                Swal.fire(translate("error"), error.response.data.message || 'An error occurred', "error");
                throw error;
            } finally {
                this.UpdateItem = false;
            }
        },
        async DeleteTodo(itemId) {
            try {
                const {data} = await axios.delete(
                    `${process.env.VUE_APP_API_URL}/cors/todos/${itemId}`,
                    {headers: this.token}
                );
                await this.fetchTodosList();
                Swal.fire(translate("success"), data.message, "success");
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
                throw error
            }
        },
        async DeleteItem(itemId) {
            try {
                const {data} = await axios.delete(
                    `${process.env.VUE_APP_API_URL}/cors/categories-todos/${itemId}`,
                    {headers: this.token}
                );
                await this.fetchList();
                Swal.fire(translate("success"), data.message, "success");
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
            }
        },
        async PutItem(itemId) {
            try {
                let name = this.currentItem.name
                const {data} = await axios.put(
                    `${process.env.VUE_APP_API_URL}/cors/categories-todos/${itemId}`,
                    {
                        name:name
                    },{headers: this.token}
                );
                await this.fetchList();
                Swal.fire(translate("success"), data.message, "success");
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
            }
        },
        async fetchList() {
            this.loading = true;
            try {
                const {data} = await axios.get(
                    `${process.env.VUE_APP_API_URL}/cors/categories-todos`,
                    {
                        params: {page: this.current_page, per_page: this.per_page, filters: this.filters},
                        headers: this.token,
                    }
                );
                this.ItemsList = data.result.categories_todos
            } catch (error) {
                console.error(error);
                Swal.fire(translate("error"), error.response.data.message, "error");
            } finally {
                this.loading = false;
            }
        },
        async fetchTodosList() {
            this.loading = true;
            try {
                const {data} = await axios.get(
                    `${process.env.VUE_APP_API_URL}/cors/todos`,
                    {
                        params: {page: this.current_page, per_page: this.per_page, filters: this.filters},
                        headers: this.token,
                    }
                );
                this.TodosItemsList = data.result.todos;
            } catch (error) {
                console.error(error);
                Swal.fire(translate("error"), error.response.data.message, "error");
            } finally {
                this.loading = false;
            }
        },
        async fetchTodoItem(id) {
            try {
                const {data} = await axios.get(
                    `${process.env.VUE_APP_API_URL}/cors/todos/${id}`,
                    {headers: this.token}
                );
                this.currentItem = data.result.item;
            } catch (error) {
                console.error(error);
                Swal.fire(translate("error"), error.response.data.message, "error");
            }
        },
        async fetchItem(id) {
            try {
                const {data} = await axios.get(
                    `${process.env.VUE_APP_API_URL}/cors/categories-todos/${id}`,
                    {headers: this.token}
                );
                this.currentItem = data.result.item;
            } catch (error) {
                console.error(error);
                Swal.fire(translate("error"), error.response.data.message, "error");
            }
        },
    },
});
