import {defineStore} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import {formatDate, translate} from "@/utils";
import moment from "moment";

export const useProjectTagsStore = defineStore("projects_settings_tags", {
    state: () => ({
        loading: true,
        Items: [],
        currentItem: {},
        newItem: {color:'#000000'},
        CurrentItemModal: false,
        NewItemModal: false,
        UpdateItem: false,
        currentCat:null
    }),
    getters: {
        token() {
            const authToken = useAuthStore();
            return authToken.header;
        },
    },
    actions: {
        async fetchList() {
            this.loading = true;
            try {
                const {data} = await axios.get(
                    `${process.env.VUE_APP_API_URL}/cors/tags`,
                    {
                        headers: this.token,
                    }
                );
                console.log('asdm',data.result.Items)
                this.Items = data.result.Items;
            } catch (error) {
                console.error(error);
                Swal.fire(translate("error"), error.response?.data?.message, "error");
            } finally {
                this.loading = false;
            }
        },
        async CreateNewItem() {

            this.UpdateItem = true;
            try {
                const {data} = await axios.post(
                    `${process.env.VUE_APP_API_URL}/cors/tags`,
                    this.newItem,
                    {headers: this.token}
                );
                this.Items.unshift(data.result.item);
                Swal.fire(translate("success"), data.message, "success");
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
            } finally {
                delete this.newItem.name
                this.UpdateItem = false;
            }
        },
        async fetchItem(id) {
            try {
                const {data} = await axios.get(
                    `${process.env.VUE_APP_API_URL}/cors/tags/${id}`,
                    {headers: this.token}
                );
                this.currentItem = data.result.item;
            } catch (error) {
                console.error(error);
                Swal.fire(translate("error"), error.response.data.message, "error");
            }
        },
        async PutItem(itemId) {
            try {
                let name = this.currentItem.name
                const {data} = await axios.put(
                    `${process.env.VUE_APP_API_URL}/cors/tags/${itemId}`,
                    {
                        name:name,
                        color:this.currentItem.color
                    },{headers: this.token}
                );
                await this.fetchList();
                Swal.fire(translate("success"), data.message, "success");
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
            }
        },
        async DeleteItem(itemId) {
            try {
                const {data} = await axios.delete(
                    `${process.env.VUE_APP_API_URL}/cors/tags/${itemId}`,
                    {headers: this.token}
                );
                await this.fetchList();
                Swal.fire(translate("success"), data.message, "success");
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
            }
        },
        async putTodoOrder(newOrder) {
            this.UpdateItem = true;
            const todoIds = newOrder.map(item => item.id);

            try {
                await axios.post(
                    `${process.env.VUE_APP_API_URL}/cors/tags/reordar`,
                    { tags: todoIds },
                    { headers: this.token }
                );
                // await this.fetchTodosList();
            } catch (error) {
                Swal.fire(translate("error"), error.response?.data?.message || "An error occurred", "error");
            } finally {
                this.UpdateItem = false;
            }
        },


    },
});
