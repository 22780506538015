// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import {translate} from "@/utils";

export const useCarCommentStore = defineStore("comments", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 400,
        current_page: 1,
        filters: [],
        currentItem: {},
        newItem: {},
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar: 'comments',
        apiSingle: 'item',
        sucessmessage: false,
        errormessage: false,
        process: false,

    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.header;
            return token
        },
    },
    actions: {
        CreateNewItem() {
            this.sucessmessage = false
            this.errormessage = false
            this.process = true
            let data = this.newItem

            axios.post(process.env.VUE_APP_API_URL + '/cors/comments', data, {
                    headers: this.token
                }
            ).then(res => {
                this.UpdateItem = false
                this.ItemsList.unshift(res.data.result.comment)
                this.newItem = {}
                this.process = false
            }).catch(error => {
                this.process = false
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        DeleteItem(i, itemId) {
            axios.delete(process.env.VUE_APP_API_URL + '/cors/comments/' + itemId, {
                    headers: this.token
                }
            ).then(res => {
                this.ItemsList.splice(i, 1)
                Swal.fire(translate("success"), res.data.message, "success");
                this.fetchList()
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        PutItem(comment) {

            axios.post(process.env.VUE_APP_API_URL + '/cors/comments/switch-pinned/' + comment.id, '',{
                headers: this.token
            }).then(res => {
                this.UpdateItem = false
                let findItem = this.ItemsList.findIndex(x => x.id === comment.id)
                if (findItem !== -1) { // If the item is found
                    let item = this.ItemsList.splice(findItem, 1)[0]; // Remove the item from the array
                    item.is_pinned = comment.is_pinned === '1' ? '0' : '1'; // Update the property
                    this.ItemsList.unshift(item); // Add the item to the beginning of the array
                }

            }).catch(error => {
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        async fetchList(carId) {
            if(carId !== undefined){
                this.loading = true
                await axios.get(process.env.VUE_APP_API_URL + '/cors/comments/get-comments/' + carId, {
                    params: {
                        filters: this.filters
                    },
                    headers: this.token,
                }).then(res => {
                    this.ItemsList = res.data.result
                    if(res.data.result.length > 0){
                        this.ItemsList.sort((a, b) => {
                            // If a is pinned and b is not, a comes first
                            if (a.is_pinned === '1' && b.is_pinned !== '1') {
                                return -1;
                            }
                            // If b is pinned and a is not, b comes first
                            else if (b.is_pinned === '1' && a.is_pinned !== '1') {
                                return 1;
                            }
                            // Otherwise, maintain the original order
                            else {
                                return 0;
                            }
                        });
                    }
                    this.loading = false
                }).catch(error => {
                    this.loading = false
                    console.log(error)
                    Swal.fire(translate("error"), error.response.data.message, "error");

                })
            }

        },
        async fetchTodoCommentsList(todoId) {
            if(todoId !== undefined){
                this.loading = true
                await axios.get(process.env.VUE_APP_API_URL + '/cors/comments/get-comments/todo/' + todoId, {
                    params: {
                        filters: this.filters
                    },
                    headers: this.token,
                }).then(res => {
                    this.ItemsList = res.data.result
                    if(res.data.result.length > 0){
                        this.ItemsList.sort((a, b) => {
                            // If a is pinned and b is not, a comes first
                            if (a.is_pinned === '1' && b.is_pinned !== '1') {
                                return -1;
                            }
                            // If b is pinned and a is not, b comes first
                            else if (b.is_pinned === '1' && a.is_pinned !== '1') {
                                return 1;
                            }
                            // Otherwise, maintain the original order
                            else {
                                return 0;
                            }
                        });
                    }
                    this.loading = false
                }).catch(error => {
                    this.loading = false
                    console.log(error)
                    Swal.fire(translate("error"), error.response.data.message, "error");

                })
            }

        },
        async fetchProjectsCommentsList(todoId) {
            if(todoId !== undefined){
                this.loading = true
                await axios.get(process.env.VUE_APP_API_URL + '/cors/comments/get-comments/task/' + todoId, {
                    params: {
                        filters: this.filters
                    },
                    headers: this.token,
                }).then(res => {
                    this.ItemsList = res.data.result
                    if(res.data.result.length > 0){
                        this.ItemsList.sort((a, b) => {
                            // If a is pinned and b is not, a comes first
                            if (a.is_pinned === '1' && b.is_pinned !== '1') {
                                return -1;
                            }
                            // If b is pinned and a is not, b comes first
                            else if (b.is_pinned === '1' && a.is_pinned !== '1') {
                                return 1;
                            }
                            // Otherwise, maintain the original order
                            else {
                                return 0;
                            }
                        });
                    }
                    this.loading = false
                }).catch(error => {
                    this.loading = false
                    console.log(error)
                    Swal.fire(translate("error"), error.response.data.message, "error");

                })
            }

        },
    },

});
