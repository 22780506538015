// roles.js
import { defineStore } from "pinia";
import { useAuthStore } from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { translate } from "@/utils";

export const useGlobalStore = defineStore("global", {
    state: () => ({
        adminRoles: [],
        paymentMethods: [],
        allLists: {},
        MyArticles: [],
        SettingsItemsList: [],
        enumList: [],
        articleListFetched: false,
        loading: false, // Define loading state
    }),
    getters: {
        token() {
            const authStore = useAuthStore();
            return authStore.header;
        },
        years() {
            const currentYear = new Date().getFullYear();
            return Array.from({ length: 150 }, (_, i) => currentYear - i);
        },
        numbersList() {
            return Array.from({ length: 50 }, (_, i) => i + 1);
        },
    },
    actions: {
        async fetchSettings() {
            if (this.SettingsItemsList.length === 0) {
                this.loading = true;
                try {
                    const res = await axios.get(`${process.env.VUE_APP_API_URL}/cors/setting/get-setting`, {
                        headers: this.token,
                    });
                    this.SettingsItemsList = res.data.result.item;
                } catch (error) {
                    Swal.fire(translate("error"), error.response?.data?.message || error.message, "error");
                } finally {
                    this.loading = false;
                }
            }
        },
        async fetchAdminRoles() {
            this.loading = true;
            try {
                const res = await axios.get(`${process.env.VUE_APP_API_URL}/core/common/list/roles`, {
                    headers: this.token,
                });
                this.adminRoles = res.data.result.list;
            } catch (error) {
                Swal.fire(translate("error"), error.response?.data?.message || error.message, "error");
            } finally {
                this.loading = false;
            }
        },
        async fetchPaymentMethods() {
            this.loading = true;
            try {
                const res = await axios.get(`${process.env.VUE_APP_API_URL}/core/common/list/subscription-payment-method`, {
                    headers: this.token,
                });
                this.paymentMethods = res.data.result.list.map(x => x.id);
            } catch (error) {
                Swal.fire(translate("error"), error.response?.data?.message || error.message, "error");
            } finally {
                this.loading = false;
            }
        },
        async fetchMyArticles() {
            this.loading = true;
            try {
                const res = await axios.get(`${process.env.VUE_APP_API_URL}/core/common/list/my-articles`, {
                    headers: this.token,
                });
                this.MyArticles = res.data.result.list;
                this.articleListFetched = true;
            } catch (error) {
                Swal.fire(translate("error"), error.response?.data?.message || error.message, "error");
            } finally {
                this.loading = false;
            }
        },
        async fetchBulkList() {
            if (Object.keys(this.allLists).length === 0) {
                const lists = [
                    'type-purchases', 'my-payment_methods', 'my-vat-methods-invoicing', 'car-categories', 'car-brands', 'car-models', 'car-versions',
                    'car-status', 'secondary-fuels', 'primary-fuels', 'meter-units', 'efect-units', 'fuel-consumption-mixed-units', 'drive-method',
                    'gearbox', 'CO2-emission-units', 'weight-units', 'my-vat-methods', 'my-users', 'my-creditors', 'equipment', 'car-body',
                    'cam-belt-replaced', 'service-book', 'my-distributors'
                ];
                try {
                    const [listsRes, enumListRes] = await Promise.all([
                        axios.get(`${process.env.VUE_APP_API_URL}/core/common/list/bulklists`, {
                            headers: this.token,
                            params: { lists },
                        }),
                        axios.get(`${process.env.VUE_APP_API_URL}/core/common/getConstList`, {
                            headers: this.token,
                        })
                    ]);
                    this.allLists = listsRes.data.result.lists;
                    this.enumList = enumListRes.data.result.lists;
                } catch (error) {
                    Swal.fire(translate("error"), error.response?.data?.message || error.message, "error");
                }
            }
        }
    },
});
