import {createWebHistory, createRouter} from "vue-router";
import {useAuthStore} from "@/state/pinia";
import routes from "./routes";
import appConfig from "../app.config.json";
import {user_can} from "@/utils";
import i18n from '../i18n'; // Import the i18n instance
const router = createRouter({
    history: createWebHistory(),
    routes,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    mode: "history",
    // Simulate native-like scroll behavior when navigating to a new
    // route and using back/forward buttons.
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    }
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    const publicPages = ["/login", "/register", "/forgot-password"];
    const authPage = !publicPages.includes(routeTo.path);
    const loggedUser = getCookie("synqiacompany");

    if (authPage && !loggedUser) {
        return next("/login");
    }

    // Debug logs
    console.log("Navigating to:", routeTo.path);
    console.log("Route Meta:", routeTo.meta);

    const titleKey = routeTo.meta?.title;
    if (titleKey) {
        console.log("Title Key:", titleKey);
        console.log("Translation Output:", i18n.global.t(titleKey));
        routeTo.meta.title = i18n.global.t(titleKey);
    }

    next();
});


router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...
                            // Complete the redirect.
                            next(...args);
                            reject(new Error("Redirected"));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    // Otherwise, continue resolving the route.
                    resolve();
                }
            });
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return;
    }

    document.title = routeTo.meta.title + " | " + appConfig.title;
    console.log('rouuuter', routeTo)
    // If we reach this point, continue resolving the route.
    next();
});

export default router;

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
