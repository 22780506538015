import {defineStore} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import {formatDate, translate} from "@/utils";
import moment from "moment";
import {integer} from "@vuelidate/validators";

export const useProjectsStore = defineStore("projects", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        TodosItemsList: [],
        ItemsInfo: {},
        per_page: 1000,
        current_page: 1,
        filters: [],
        currentItem: {},
        newItem: {},
        CurrentItemModal: false,
        NewItemModal: false,
        UpdateItem: false,
        currentCat:null,
        newProjectCustomJsonData :[
            {
                type:'repeater',
                key:'car_details',
                items:[
                    {
                        type:'text',
                        key:'reg_nr',
                        settings_key:null
                    },
                    {
                        type:'text',
                        key:'meter_settings',
                        settings_key:'mil'
                    },
                    {
                        type:'text',
                        key:'service_history',
                        settings_key:null
                    },
                    {
                        type:'date',
                        key:'last_service_date',
                        settings_key:'mil'
                    },
                    {
                        type:'text',
                        key:'general_looks',
                        settings_key:null
                    },
                    {
                        type:'text',
                        key:'extra_parts',
                        settings_key:null
                    },
                    {
                        type:'text',
                        key:'expected_sales_date',
                        settings_key:null
                    },
                ]
            },
            {
                type:'repeater',
                key:'customer_details',
                items:[
                    {
                        type:'text',
                        key:'name',
                        settings_key:null
                    },
                    {
                        type:'text',
                        key:'company',
                        settings_key:null
                    },
                    {
                        type:'text',
                        key:'address',
                        settings_key:null
                    },
                    {
                        type:'email',
                        key:'email',
                        settings_key:null
                    },
                    {
                        type:'text',
                        key:'phone',
                        settings_key:null
                    },
                ]
            },
        ]
    }),
    getters: {
        token() {
            const authToken = useAuthStore();
            return authToken.header;
        },
    },
    actions: {
        async CreateNewItem() {

            this.UpdateItem = true;
            try {
                const {data} = await axios.post(
                    `${process.env.VUE_APP_API_URL}/cors/categories-tasks`,
                    this.newItem,
                    {headers: this.token}
                );
                this.ItemsList.unshift(data.result.item);
                Swal.fire(translate("success"), data.message, "success");
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
            } finally {
                this.UpdateItem = false;
            }
        },
        async putTodoOrder(newOrder) {
            this.UpdateItem = true;
            const todoIds = newOrder.map(item => item.id);

            try {
                await axios.post(
                    `${process.env.VUE_APP_API_URL}/cors/categories-tasks/reordar`,
                    { categories: todoIds },
                    { headers: this.token }
                );
               // await this.fetchTodosList();
            } catch (error) {
                Swal.fire(translate("error"), error.response?.data?.message || "An error occurred", "error");
            } finally {
                this.UpdateItem = false;
            }
        },
        async putTaskOrder(newOrder) {
            this.UpdateItem = true;
            const todoIds = newOrder.map(item => parseInt(item.id));
            try {
                await axios.post(
                    `${process.env.VUE_APP_API_URL}/cors/tasks/reordar`,
                    {'tasks':todoIds},
                    { headers: this.token }
                );
               // await this.fetchTodosList();
            } catch (error) {
                Swal.fire(translate("error"), error.response?.data?.message || "An error occurred", "error");
            } finally {
                this.UpdateItem = false;
            }
        },

        async createTodo(e) {
            this.UpdateItem = true;
            console.log(e)
            const formData = new FormData();
            const fields = ['end_date', 'is_pinned', 'category_id', 'desc', 'date', 'status'];

// Loop through each field and append to formData if it exists in 'e'
            fields.forEach((field) => {
                if (e[field] !== undefined) {
                    formData.append(field, Array.isArray(e[field]) ? JSON.stringify(e[field]) : e[field]);
                }
            });

            Array.from(e.assigned_to).forEach(x => formData.append('assigned_to[]', x));
            if(e.tag_tasks?.length > 0){
                Array.from(e.tag_tasks).forEach(x => formData.append('tag_tasks[]', x.id));
            }
            formData.append('json_data',JSON.stringify(this.newProjectCustomJsonData))

            e.image !== undefined && e.image.length > 0 ? Array.from(e.image).forEach(x => formData.append('image[]', x)) : '';

            try {
                const {data} = await axios.post(
                    `${process.env.VUE_APP_API_URL}/cors/tasks`,
                    formData,
                    {headers: {...this.token, 'Content-Type': 'multipart/form-data'}}
                );

                // If the API call succeeds, show a success message
                let catIn = this.TodosItemsList.findIndex(x => x.id === e.category_id);
                console.log(data);
                this.TodosItemsList[catIn].tasks.unshift(data.result.item);
                Swal.fire(translate("success"), data.message, "success");

                return data;  // Return success data if needed
            } catch (error) {
                // Show error message returned from backend
                Swal.fire(translate("error"), error.response.data.message || 'An error occurred', "error");
                throw error;
            } finally {
                this.UpdateItem = false;
            }
        },
        async putTodo(item) {
            let e = {...item}
            this.UpdateItem = true;
            const itemId = e.id
            delete e.created_at
            delete e.updated_at
            delete e.created_by
            delete e.company_id
            delete e.id
            const formData = new FormData();
         //   let parsedDate = e.date
          /*  let parsedDate = new Date(e.date);
            window.alert(parsedDate)
            if (isNaN(parsedDate.getTime())) {
                // If parsing fails, set e.date to the current date
                window.alert(1)
                parsedDate = moment().format('YYYY-MM-DD');
            }else{
                window.alert(2)
                parsedDate = moment(parsedDate).format('YYYY-MM-DD');
            }*/


            const fields = ['end_date', 'is_pinned', 'category_id', 'desc', 'status','date'];
            formData.append('_method','PUT')
          //  formData.append('date',parsedDate)

// Loop through each field and append to formData if it exists in 'e'
            fields.forEach((field) => {
                if (e[field] !== undefined) {
                    formData.append(field, Array.isArray(e[field]) ? JSON.stringify(e[field]) : e[field]);
                }
            });

            Array.from(e.assigned_users).forEach((x,i)=> formData.append('assigned_to[]', x.id));
            if(e.tag_tasks?.length > 0){
                Array.from(e.tag_tasks).forEach(x => formData.append('tag_tasks[]', x.id));
            }
            e.image !== undefined && e.image.length > 0 ? Array.from(e.image).forEach(x => formData.append('image[]', x)) : '';

            try {
                const {data} = await axios.post(
                    `${process.env.VUE_APP_API_URL}/cors/tasks/${itemId}`,
                    formData,
                    {headers: {...this.token, 'Content-Type': 'multipart/form-data'}}
                );

                // If the API call succeeds, show a success message
                await this.fetchTaskItem(item.id)
              await this.fetchTodosList();
               // Swal.fire(translate("success"), data.message, "success");

                return data;  // Return success data if needed
            } catch (error) {
                // Show error message returned from backend
                Swal.fire(translate("error"), error.response.data.message || 'An error occurred', "error");
                throw error;
            } finally {
                this.UpdateItem = false;
            }
        },
        async DeleteTodo(itemId) {
            try {
                const {data} = await axios.delete(
                    `${process.env.VUE_APP_API_URL}/cors/tasks/${itemId}`,
                    {headers: this.token}
                );
                await this.fetchTodosList();
                Swal.fire(translate("success"), data.message, "success");
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
                throw error
            }
        },
        async PutItem(itemId) {
            try {
                let name = this.currentItem.name
                const {data} = await axios.put(
                    `${process.env.VUE_APP_API_URL}/cors/categories-tasks/${itemId}`,
                    {
                        name:name,
                        email:this.currentItem.email
                    },{headers: this.token}
                );
                await this.fetchList();
                Swal.fire(translate("success"), data.message, "success");
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
            }
        },
        async DeleteItem(itemId) {
            try {
                const {data} = await axios.delete(
                    `${process.env.VUE_APP_API_URL}/cors/categories-tasks/${itemId}`,
                    {headers: this.token}
                );
                await this.fetchList();
                Swal.fire(translate("success"), data.message, "success");
            } catch (error) {
                Swal.fire(translate("error"), error.response.data.message, "error");
            }
        },
        async fetchList() {
            this.loading = true;
            try {
                const {data} = await axios.get(
                    `${process.env.VUE_APP_API_URL}/cors/categories-tasks`,
                    {
                        params: {filters: this.filters},
                        headers: this.token,
                    }
                );
                this.ItemsList = data.result.categories_tasks;
            } catch (error) {
                console.error(error);
                Swal.fire(translate("error"), error.response.data.message, "error");
            } finally {
                this.loading = false;
            }
        },
        async fetchTodosList() {
            this.loading = true;
            try {
                const {data} = await axios.get(
                    `${process.env.VUE_APP_API_URL}/cors/tasks`,
                    {
                        params: {filters: this.filters},
                        headers: this.token,
                    }
                );
                this.TodosItemsList = data.result.items;
            } catch (error) {
                console.error(error);
                Swal.fire(translate("error"), error.response.data.message, "error");
            } finally {
                this.loading = false;
            }
        },
        async fetchTaskItem(id,modal = false) {
            try {
                const {data} = await axios.get(
                    `${process.env.VUE_APP_API_URL}/cors/tasks/${id}`,
                    {headers: this.token}
                );
                this.currentItem = data.result.item;
                if(modal){
                    this.CurrentItemModal = true
                }
            } catch (error) {
                console.error(error);
                Swal.fire(translate("error"), error.response.data.message, "error");
            }
        },
        async fetchItem(id) {
            try {
                const {data} = await axios.get(
                    `${process.env.VUE_APP_API_URL}/cors/categories-tasks/${id}`,
                    {headers: this.token}
                );
                this.currentItem = data.result.item;
            } catch (error) {
                console.error(error);
                Swal.fire(translate("error"), error.response.data.message, "error");
            }
        },
    },
});
