import { defineStore } from "pinia";
import axios from "axios";
import { encrypt, user_can } from "@/utils";
import Swal from "sweetalert2";
import { useAuthStore } from "@/state/pinia/auth";
import { translate } from "@/utils";
import moment from "moment";
import qs from "qs";
export const useTestDriveSettingsStore = defineStore("testdrivesettings", {
    state: () => ({
        UpdateItem: false,
        newItem:false,
        preventSuccessMsg: false,
        CurrentItemModal: false,
        ItemsList: [],
        PostItemsList: [],
        loading: true,
        current_page: 1,
        filters: {},
        totalItems: 0,
        ItemsInfo: {},
        itemsPerPage: 400,
    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.header;
            return token
        },
    },
    actions: {
        async fetchPreDriveList() {
            try {
                const hasPermission = await user_can('read_cars');
                if (hasPermission) {
                    this.loading = true;
                    const res = await axios.get(`${process.env.VUE_APP_API_URL}/cors/pre-drives`, {
                        params: {
                            page: this.current_page,
                            per_page: this.per_page,
                        },
                        headers: this.token,
                    });

                    this.ItemsList = res.data.result.pre_drives.data;
                    this.loading = false;
                }
            } catch (error) {
                this.loading = false;
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },
        async fetchPostDriveList() {
            try {
                const hasPermission = await user_can('read_cars');
                if (hasPermission) {
                    this.loading = true;
                    const res = await axios.get(`${process.env.VUE_APP_API_URL}/cors/post-drives`, {
                        params: {
                            page: this.current_page,
                            per_page: this.per_page,
                        },
                        headers: this.token,
                    });

                    this.PostItemsList = res.data.result.post_drives.data;
                    this.loading = false;
                }
            } catch (error) {
                this.loading = false;
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },

        async fetchItem(id) {
            try {
                const hasPermission = await user_can('read_cars');
                if (hasPermission) {
                    const res = await axios.get(`${process.env.VUE_APP_API_URL}/cors/pre-drives/${id}`, {
                        headers: this.token,
                    });
                    this.currentItem = res.data.result.item;
                } else {
                    throw new Error('No permission to read creditors.');
                }
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },

        async CreateNewItem(payload) {
            this.UpdateItem = true

            try {
                    const res = await axios.post(`${process.env.VUE_APP_API_URL}/cors/pre-drives`, payload,{
                        headers: this.token,
                    });

                    this.ItemsList.push(res.data.result.item);
                    this.UpdateItem = false
            } catch (error) {
                this.UpdateItem = false
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },
        async CreatePostDriveNewItem(payload) {
            this.UpdateItem = true

            try {
                    const res = await axios.post(`${process.env.VUE_APP_API_URL}/cors/post-drives`, payload,{
                        headers: this.token,
                    });

                    this.PostItemsList.push(res.data.result.item);
                    this.UpdateItem = false
            } catch (error) {
                this.UpdateItem = false
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },
        async DeleteItem(i, itemId) {
            try {
                const res = await axios.delete(`${process.env.VUE_APP_API_URL}/cors/pre-drives/`+itemId,{
                    headers: this.token,

                });

                this.ItemsList.splice(i,1)
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }


        },
        async DeletePostItem(i, itemId) {
            try {
                const res = await axios.delete(`${process.env.VUE_APP_API_URL}/cors/post-drives/`+itemId,{
                    headers: this.token,

                });

                this.PostItemsList.splice(i,1)
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }


        },

    },


});
